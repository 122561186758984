const oceans = [
  {
    id: 0,
    primaryColor: '#8E98B8',
    secondaryColor: '#DCE0EF',
    image: 'service1.png',
    icon: 'wealth.svg',
    email: 'welcome@oceanwealth.ae',
    title: 'Navigating Financial Horizon with Confidence',
    description: 'Tailored Wealth Management Services for High-Net-Worth Individuals and Families',
    aboutUs: {
      title: 'At Ocean Wealth, we are dedicated to providing exceptional wealth management for high-net-worth individuals and families.',
      description: 'With a deep understanding of the complexities of wealth, we navigate the financial landscape with expertise, transparency, and unwavering commitment.'
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'Comprehensive Wealth Management',
        description: 'We offer a full suite of wealth management services to address every aspect of your financial well-being. From investment planning to estate planning, tax optimization, philanthropic endeavors, and family governance we provide a holistic approach to managing wealth.'
      },
      {
        id: 1,
        title: 'AI and Algorithms',
        description: 'By harnessing the power of AI, we unlock valuable patterns, trends, and predictive analytics that drive intelligent decision-making. Our sophisticated algorithms enable us to optimize portfolio management, risk assessment, asset allocation, and market forecasting, empowering us to deliver superior returns and mitigate risks in dynamic market conditions.'
      },
      {
        id: 2,
        title: 'Technology',
        description: 'Our team of professionals stays at the forefront of technological advancements, leveraging sophisticated tools and platforms to enhance investment strategies, automate processes, and provide real-time insights. By seamlessly integrating technology into operations, we ensure efficiency, accuracy, and agility in managing wealth.'
      },
      {
        id: 3,
        title: 'Real Estate',
        description: 'Our real estate expertise extends across the globe, including prime locations such as the UAE, Italy, Spain, Germany, Switzerland, the UK, and Scandinavia. We offer tailored advisory services for residential, commercial, and alternative real estate investments. Deep understanding of regional dynamics and regulations allows us to provide comprehensive market analysis, due diligence, and strategic guidance.'
      }
    ],
    approach: {
      title: 'Intelligent Wealth Management for Long-Term Prosperity',
      description: "We embrace an intelligent approach to wealth management, combining cutting-edge technologies with our deep expertise to guide our clients towards long-term prosperity. Our data-driven strategies and advanced tools, including trading algorithms, machine learning, and artificial intelligence, empower us to make informed decisions and adapt to evolving market conditions.\n\nWith a focus on capital preservation and intergenerational wealth, we strive to provide personalized solutions that unlock opportunities and drive sustainable financial growth."
    },
    portfolio: [

    ],
    contactTitle: 'Unlock the Path to financial excellence'
  },
  {
    id: 1,
    primaryColor: '#5E617B',
    secondaryColor: '#DFDFE4',
    image: 'service2.png',
    icon: 'genesis.svg',
    email: 'welcome@oceangenesis.ae',
    title: 'Charting Course to Financial Success',
    description: 'Strategic capital management for innovative wealth growth and redefined financial success.',
    aboutUs: {
      title: "From comprehensive financial planning and investment management to philanthropic guidance and legacy planning, our comprehensive suite of services is designed to empower your family's journey to prosperity and impact.",
      description: ''
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'Legacy Planning',
        description: "Preserving and passing down your family's legacy is our passion. We work closely with you to create tailored legacy plans, ensuring that your wealth and values endure, leaving a lasting impact on the world."
      },
      {
        id: 1,
        title: 'Family Governance',
        description: 'We understand the complexities of family dynamics and the importance of governance structures. Our experts offer guidance in establishing family charters, facilitating family meetings, and resolving intergenerational issues to foster unity and harmony.'
      },
      {
        id: 2,
        title: 'Risk Management',
        description: "Preserving your family's financial security is paramount. We assess risks and implement risk management strategies to safeguard your wealth from unforeseen events."
      }
    ],
    approach: {
      title: 'Empowering Your Legacy',
      description: "We believe that wealth management should go beyond just numbers on a balance sheet. Our family-centric philosophy puts your family's values, goals, and aspirations at the forefront of our strategies.\n\nBy taking the time to understand your family dynamics and long-term objectives, we can craft a comprehensive financial plan that reflects your unique identity and preserves your legacy for generations to come."
    },
    portfolio: [],
    contactTitle : 'Unlock the Path to financial excellence'
  },
  {
    id: 2,
    primaryColor: '#A88A82',
    secondaryColor: '#F2E4E5',
    image: 'service3.png',
    icon: 'ventures.svg',
    title: "Shaping Tomorrow's Financial Landscape",
    email: 'welcome@oceanventures.ae',
    description: 'We provide capital and resources to fuel growth and drive impactful change.',
    aboutUs: {
      title: "Ocean Venture capital division focuses on investing in innovative startups and companies that leverage Blockchain, Artificial Intelligence, Machine Learning, Big Data, and Trading Algorithms.",
      description: ''
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'Trading Algorithms',
        description: "We are passionate about identifying and investing in startups that are pioneering novel trading algorithms. These algorithms leverage advanced data analysis, predictive modeling, and adaptive strategies to navigate complex market landscapes."
      },
      {
        id: 1,
        title: 'Artificial Intelligence and Machine Learning',
        description: 'Our dedication to AI and machine learning is driven by their capacity to unlock insights from vast datasets and automate decision-making processes. We seek out startups that are at the forefront of developing AI-powered tools that analyze market behavior, identify patterns, and adapt strategies in real-time.'
      },
      {
        id: 2,
        title: 'Decentralized Finance (DeFi)',
        description: "Ocean Ventures recognizes the disruptive potential of DeFi. We invest in startups that are creating innovative mathematical models within the DeFi ecosystem. These models provide decentralized solutions for lending, borrowing, trading, and yield optimization, contributing to the democratization of financial services."
      }
    ],
    approach: {
      title: '',
      description: "Our approach at Ocean Ventures is rooted in a deep understanding of the ever-evolving landscape of technology-driven finance. We recognize the profound impact that trading algorithms, AI, and machine learning have on reshaping traditional financial paradigms. These technologies not only enhance decision-making processes but also unlock new avenues for generating sustainable returns."
    },
    portfolio: [
      {
        id: 0,
        title: 'Nanos payments',
        image: 'nanos-payment.svg'
      },
      {
        id: 1,
        title: 'Ocean Finance',
        image: 'ocean-finance.svg'
      }
    ],
    contactTitle : 'Navigating Innovation for Strategic Investment Success'
  },
  {
    id: 3,
    primaryColor: '#786A64',
    secondaryColor: '#E4E0DB',
    image: 'service4.png',
    icon: 'finance.svg',
    title: "Unlocking Future Success with Innovative Investments",
    email: 'welcome@oceanfinance.ae',
    description: 'Redefining Digital Asset Trading Landscape through AI and Innovation',
    aboutUs: {
      title: "Ocean Finance, based in United Arab Emirates, is a algorithmic trading fund specializing in quantitative research within the digital asset domain.",
      description: 'Leveraging the power of AI, Machine Learning, and advanced trading algorithms, we predict market prices with precision. Regulated by the Virtual Assets Regulatory Authority (VARA), our commitment to innovation, technology, and expertise drives us to optimize investment strategies and redefine financial possibilities.'
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'Algorithmic Trading Strategies',
        description: "Our proficiency in AI technology and sophisticated trading algorithms empowers us to anticipate market prices, enabling informed investment decisions that maximize returns."
      },
      {
        id: 1,
        title: 'Quantitative Research Insights',
        description: 'Through comprehensive quantitative research, we uncover data-driven insights within digital assets, facilitating strategic investment planning and risk management.'
      },
      {
        id: 2,
        title: 'Financial Technology Innovation',
        description: "At the forefront of financial technology innovation, we leverage Blockchain, Artificial Intelligence, Machine Learning, Big Data, and Trading Algorithms to create advanced solutions that redefine investment strategies and enhance efficiency. "
      }
    ],
    approach: {
      title: 'Innovation and competence drive us beyond reality',
      description: "At Ocean Finance, our approach is a fusion of visionary thinking and cutting-edge technology. Guided by a foundation built on expertise, we navigate the intricacies of the financial landscape with the precision it demands. Our team's deep industry knowledge, nurtured over years, forms the foundation for informed decision-making.\n\nThe pursuit of excellence runs as a thread through every aspect of our operations. "
    },
    portfolio: [],
    contactTitle : 'Embark on a journey of financial excellence.'
  },
  {
    id: 4,
    primaryColor: '#545762',
    secondaryColor: '#E1E3E7',
    image: 'service9.png',
    icon: 'commodities.svg',
    title: "Elevating Global Trade",
    email: 'welcome@oceancommodities.ae',
    description: 'Seamless commodities transactions on a global scale, ensuring transparency and efficiency.',
    aboutUs: {
      title: "Ocean Commodities is part of Ocean Finance, regulated financial institution specializing in international commodity trading.",
      description: ''
    },
    expertiseTitle: 'Key specialties',
    expertise: [
      {
        id: 0,
        title: 'International Commodity Trading',
        description: "We navigate gold, silver, and platinum markets with precision, turning metal into profitable opportunities."
      },
      {
        id: 1,
        title: 'Regulated Financial Services',
        description: 'As a regulated institution, we provide secure financial services, ensuring compliance and trust in all our commodity transactions.'
      },
      {
        id: 2,
        title: 'Market Insights and Analysis',
        description: "Our team of experts offers market insights and analysis to aid informed decision-making, optimizing commodity trade strategies in an ever-changing landscape."
      }
    ],
    approach: {
      title: '',
      description: "We combine regulatory compliance with advanced financial tools to create a secure and efficient environment for international commodity trading.\n\nOur approach embodies trust, ensuring transparent transactions, and expertise, providing market insights that empower clients to make confident trading decisions."
    },
    portfolio: [],
    contactTitle : 'Secure and efficient trading solutions, enabling smooth international commodity transactions.'
  },
  {
    id: 5,
    primaryColor: '#325361',
    secondaryColor: '#D4DCE2',
    image: 'service5.png',
    icon: 'corporate-provider.svg',
    email: 'welcome@oceancs.ae',
    title: "Destination for Legal and Strategic Business Solutions",
    description: '',
    aboutUs: {
      title: "At Ocean Corporate Provider, we specialize in delivering comprehensive legal services for business management.",
      description: 'Our expertise extends to local and international consulting, offering tailored solutions for business creation and growth.'
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'Comprehensive Business Solutions',
        description: "Our legal expertise guides businesses through complex regulatory landscapes, ensuring compliance and seamless operations."
      },
      {
        id: 1,
        title: 'Local and International Consulting',
        description: 'We provide strategic consulting for both local and international ventures, enabling businesses to expand and thrive in diverse markets.'
      },
      {
        id: 2,
        title: 'Regulatory Compliance Guidance',
        description: "At Ocean Corporate Provider, our approach integrates legal precision with strategic insights. By providing regulatory expertise, we ensure businesses thrive within diverse legal environments. Innovation is at our core, empowering us to craft strategies that nurture growth and prosperity.\n\nThrough Expertise and Excellence, we forge a path for businesses to succeed on both local and global scales."
      }
    ],
    approach: {
      title: '',
      description: "At Ocean Finance, our approach is a fusion of visionary thinking and cutting-edge technology. Guided by a foundation built on expertise, we navigate the intricacies of the financial landscape with the precision it demands. Our team's deep industry knowledge, nurtured over years, forms the foundation for informed decision-making.\n\nThe pursuit of excellence runs as a thread through every aspect of our operations. "
    },
    portfolio: [],
    contactTitle : 'Navigating Business Success with Expert Guidance'
  },
  {
    id: 6,
    primaryColor: '#9C8667',
    secondaryColor: '#F8F3EA',
    image: 'service6.png',
    icon: 'cremona.svg',
    title: "Redefining Luxury Real Estate in the UAE",
    email: 'welcome@oceancremona.ae',
    description: 'Experience luxurious living beyond ordinary with an elevated approach to Real Estate',
    aboutUs: {
      title: "Ocean Cremona specializes in the acquisition and sale of luxury Real Estate in the United Arab Emirates.",
      description: 'We curate exclusive Real Estate experiences that redefine opulent living.'
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'Strategic Property Sales',
        description: "We specialize in the strategic selling of high-end Real Estate, crafting tailored approaches for optimal returns."
      },
      {
        id: 1,
        title: 'Luxury Real Estate Acquisition',
        description: 'Our expertise lies in securing prestigious luxury properties, ensuring unmatched exclusivity and opulence for our clients.'
      },
      {
        id: 2,
        title: 'Insightful Real Estate Knowledge',
        description: "With a deep understanding of the UAE market, we offer informed insights to guide investment decisions."
      }
    ],
    approach: {
      title: '',
      description: "At Ocean Cremona, we harmonize expert market analysis with a dedication to exclusivity and excellence. Our sophisticated tools and insights empower clients to make informed luxury Real Estate investments.\n\nBy embracing our values of integrity and innovation, we ensure that every property transaction mirrors the Ocean Group's standards."
    },
    portfolio: [],
    contactTitle : 'Explore exclusive UAE Real Estate opportunities.'
  },
  {
    id: 7,
    primaryColor: '#6B6B6B',
    secondaryColor: '#DBDDDC',
    image: 'service7.png',
    icon: 'narbo.svg',
    title: "Unlocking Global Real Estate Opportunities",
    email: 'welcome@oceannarbo.ae', 
    description: 'Your Partner in International Property Ventures.',
    aboutUs: {
      title: "Narbo Real Estate specializes in international Real Estate acquisition and sales.",
      description: 'With a global footprint, we facilitate buying, selling, and renting properties across diverse regions including Switzerland, Germany, Italy, Spain, UK, and Scandinavia.'
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'International Real Estate Transactions',
        description: "Our expertise lies in facilitating seamless Real Estate transactions on a global scale, ensuring clients access to diverse property markets."
      },
      {
        id: 1,
        title: 'Global Property Acquisition',
        description: 'We specialize in identifying and acquiring prime properties across multiple countries, offering a broad spectrum of investment opportunities.'
      },
      {
        id: 2,
        title: 'Rental Property Solutions',
        description: "We offer comprehensive rental property services, enabling clients to secure attractive rental opportunities across international locations."
      }
    ],
    approach: {
      title: '',
      description: "At Narbo Real Estate, we merge in-depth market analysis with a dedication to customized strategies. Through our array of tools and resources, we empower clients to make well-informed Real Estate decisions.\n\nOur values of collaboration and client-centricity drive us to provide unparalleled service, ensuring every venture aligns with our commitment to excellence."
    },
    portfolio: [],
    contactTitle : 'Diverse property investments worldwide'
  },
  {
    id: 8,
    primaryColor: '#6B6B6B',
    secondaryColor: '#DBDDDC',
    image: 'service10.png',
    icon: 'elements.svg',
    title: "Unlocking Global Real Estate Opportunities",
    email: 'welcome@oceannarbo.ae', 
    description: 'Your Partner in International Property Ventures.',
    aboutUs: {
      title: "Narbo Real Estate specializes in international Real Estate acquisition and sales.",
      description: 'With a global footprint, we facilitate buying, selling, and renting properties across diverse regions including Switzerland, Germany, Italy, Spain, UK, and Scandinavia.'
    },
    expertiseTitle: 'Areas of expertise',
    expertise: [
      {
        id: 0,
        title: 'International Real Estate Transactions',
        description: "Our expertise lies in facilitating seamless Real Estate transactions on a global scale, ensuring clients access to diverse property markets."
      },
      {
        id: 1,
        title: 'Global Property Acquisition',
        description: 'We specialize in identifying and acquiring prime properties across multiple countries, offering a broad spectrum of investment opportunities.'
      },
      {
        id: 2,
        title: 'Rental Property Solutions',
        description: "We offer comprehensive rental property services, enabling clients to secure attractive rental opportunities across international locations."
      }
    ],
    approach: {
      title: '',
      description: "At Narbo Real Estate, we merge in-depth market analysis with a dedication to customized strategies. Through our array of tools and resources, we empower clients to make well-informed Real Estate decisions.\n\nOur values of collaboration and client-centricity drive us to provide unparalleled service, ensuring every venture aligns with our commitment to excellence."
    },
    portfolio: [],
    contactTitle : 'Diverse property investments worldwide'
  },
  {
    id: 9,
    primaryColor: '#2E6665',
    secondaryColor: '#CFDAD7',
    image: 'service8.png',
    icon: 'elements.svg',
    title: "Forging Future Finance with Innovative Software Solutions",
    email: 'welcome@oceanelements.ae',
    description: 'Creating cutting-edge technological solutions for finance, AI trading algorithms and crypto-related applications.',
    aboutUs: {
      title: "We are a dedicated software development company specializing in crafting trading algorithms, artificial intelligence, machine learning, and innovative technologies.",
      description: 'With a focus on expertise and innovation, we redefine the landscape of financial markets and technology-driven solutions.'
    },
    expertiseTitle: 'Our specialities',
    expertise: [
      {
        id: 0,
        title: 'Trading Algorithms',
        description: "Trading algorithms utilize various mathematical models, statistical analysis and historical data to make decisions regarding the timing, price and quantity of trades. Algorithmic trading can execute trades much faster than human traders, often in fractions of a second."
      },
      {
        id: 1,
        title: 'Artificial Intelligence',
        description: 'AI in trading involves using advanced algorithms to analyze market data and execute trades, aiming to improve decision-making and efficiency. In the context of cryptocurrency and industry, AI employs data analysis and automation to predict market trends and optimize industrial processes, respectively.'
      },
      {
        id: 2,
        title: 'Machine Learning',
        description: "Machine learning is a key aspect of AI, focusing on algorithms and models that allow computers to learn from data. It involves training models on large datasets to recognize patterns and extract insights. Our mastery of machine learning techniques enables predictive analytics, shaping the future of financial and technological solutions."
      }
    ],
    approach: {
      title: '',
      description: "By synergizing our expertise and innovation, we create impactful solutions. Our advanced toolkit helps to capture powerful insights.\n\nAnchored in integrity and driven by a spirit of partnership and agility, we navigate the evolving landscape of technology and finance."
    },
    portfolio: [],
    contactTitle : 'Innovative Software Solutions for Future Finance'
  }
]

const emails = [
  {
    icon: 'oceans.svg',
    title: 'Name Surname',
    description: 'General manager',
    email: 'legal@oceangroup.global',
    phone: '+971 586 677 606/ +971 522 487 391',
    url: 'oceangroup.global',
    content1: "Disclaimer:This email and its attachments, if any, are for the named person's use only. It may contain confidential proprietary or privileged information. No confidentiality or privilege is waived or lost by any erroneous transmission. If you have received this message in error, please immediately delete it and all copies of it from your system, destroy any hard copies of it and notify the sender. You must not, directly or indirectly, use, disclose, distribute, print, or copy any part of this message if you are not the intended recipient.",
    content2: "Aviso De Confidencialidade:Este e-mail e quaisquer ficheiros informáticos com ele transmitidos são confidenciais e destinados ao conhecimento e uso exclusivo do respectivo destinatário, não podendo o conteúdo dos mesmos ser alterado. Caso tenha recebido este e-mail indevidamente, queira informar de imediato a remetente e proceder à destruição da mensagem. O correio electrónico não garante a confidencialidade dos conteúdos das mensagens, nem a recepção adequada dos mesmos. Caso o destinatário deste e-mail tenha qualquer objecção à utilização deste melo deverá contactar de imediato o remetente.",
    content3: "Descargo de responsabilidad:Este correo electrónico y sus anexos, si es que hay alguno, son solo para uso de la persona nombrada. Podría contener información confidencial privada o privilegiada. No se exime ninguna confidencialidad o privilegio por motivo de una transmisión errónea. Si ha recibido este mensaje por equivocación, por favor borre el mismo y todas sus copias de inmediato de su sistema, destruya toda copia impresa del mismo y notifiqueselo al remitente. Usted no debe, de manera directa o indirecta, utilizar, revelar, distribuir, imprimir o copiar cualquier parte de este mensaje si no es el destinatario.",
    content4: "هذا البريد اإللكتروني ومرفقاته إن وجدت الستخدام الشخص أملسمى فقط قد تحتوي عليمعلوماتسرية خاصة عن امللكية او االمتيازات ال يتم التنازل عن أي سرية أو امتياز أو فقدانه من خالل أي نقل خاطئ. إذا تلقيت هذه الرسالة عن طريق الخطأ، فيرجى حذفها وجميع نسجها على الفور من نظامك ، وإهالك أي نسخ ورقية منها وإخطار املرسل و ال يجوز لك بشكل مباشر أو غير مباشر استخدام أو الكشف عن أو توزيع أو طباعة أو نسخ أي جزء من هذه الرسالة إذا لم تكن أنت املستلم املقصود به خال، املسؤولية",
  },
  {
    icon: 'nanos-payment.svg',
    title: 'Name Surname',
    description: 'Executive director',
    email: 'welcome@nanospay.ai',
    phone: '+971 586 677 606',
    url: 'nanospay.ai',
    content1: "Disclaimer:This email and its attachments, if any, are for the named person's use only. It may contain confidential proprietary or privileged information. No confidentiality or privilege is waived or lost by any erroneous transmission. If you have received this message in error, please immediately delete it and all copies of it from your system, destroy any hard copies of it and notify the sender. You must not, directly or indirectly, use, disclose, distribute, print, or copy any part of this message if you are not the intended recipient.",
    content2: "Aviso De Confidencialidade:Este e-mail e quaisquer ficheiros informáticos com ele transmitidos são confidenciais e destinados ao conhecimento e uso exclusivo do respectivo destinatário, não podendo o conteúdo dos mesmos ser alterado. Caso tenha recebido este e-mail indevidamente, queira informar de imediato a remetente e proceder à destruição da mensagem. O correio electrónico não garante a confidencialidade dos conteúdos das mensagens, nem a recepção adequada dos mesmos. Caso o destinatário deste e-mail tenha qualquer objecção à utilização deste melo deverá contactar de imediato o remetente.",
    content3: "Descargo de responsabilidad:Este correo electrónico y sus anexos, si es que hay alguno, son solo para uso de la persona nombrada. Podría contener información confidencial privada o privilegiada. No se exime ninguna confidencialidad o privilegio por motivo de una transmisión errónea. Si ha recibido este mensaje por equivocación, por favor borre el mismo y todas sus copias de inmediato de su sistema, destruya toda copia impresa del mismo y notifiqueselo al remitente. Usted no debe, de manera directa o indirecta, utilizar, revelar, distribuir, imprimir o copiar cualquier parte de este mensaje si no es el destinatario.",
    content4: "خال، املسؤوليةهذا البريد اإللكتروني ومرفقاته إن وجدت الستخدام الشخص أملسمى فقط قد تحتوي عليمعلوماتسرية خاصة عن امللكية او االمتيازات ال يتم التنازل عن أي سرية أو امتياز أو فقدانه من خالل أي نقل خاطئ. إذا تلقيت هذه الرسالة عن طريق الخطأ، فيرجى حذفها وجميع نسجها على الفور من نظامك ، وإهالك أي نسخ ورقية منها وإخطار املرسل و ال يجوز لك بشكل مباشر أو غير مباشر استخدام أو الكشف عن أو توزيع أو طباعة أو نسخ أي جزء من هذه الرسالة إذا لم تكن أنت املستلم املقصود به",
  }
]

export {
  oceans,
  emails
}