import React from "react";
import {
  ContactContainer,
  SidebarLinkWrap,
  SidebarActionLink,
  CloseIcon,
  SidebarWrapper,
  FormContainer,
  SideBtnWrap,
  SidebarRoute,
  SidebarDescription
} from "./ContactUsElements";
import Text from '../Text';
function ContactUs({ isOpenContactUs, toggle, bgColor, email, isArabic }) {
  const textAlign = isArabic
  ? 'text-right'
  : '';
  return (
    <ContactContainer isOpen={isOpenContactUs}>
      <SidebarLinkWrap>
        <CloseIcon onClick={toggle}/>
      </SidebarLinkWrap>
      <SidebarWrapper>
        <FormContainer bgColor={bgColor} textAlign={textAlign}/>
        <SideBtnWrap>
          <SidebarRoute to="/" textAlign={textAlign}>
            { email }
          </SidebarRoute>
          <SidebarDescription textAlign={textAlign}>
            <Text textKey="DMCC Cluster Y Office 1706 Jumeirah Business Center 3, Dubai"/>
          </SidebarDescription>
        </SideBtnWrap>
      </SidebarWrapper>
    </ContactContainer>
  );
}

export default ContactUs;
