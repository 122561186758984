import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import Text from '../Text';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import logo from '../../images/Logo(black-small).svg';
import menu from '../../images/menu.svg';
import { useDetailProps } from '../../detailProvider';

import { getValue } from '../../utils';

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;

function Navbar({ toggleMenu, toggleContactUs, isMain, config }) {
  const [scrollNav, setScrollNav] = useState(false);
  const detailProps = useDetailProps();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <>
      <Nav isMain={false} color={detailProps?detailProps.primaryColor:"#3E5D87"} scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo onClick={toggleHome}>
            {
              isMain ? <img src={logo}/> : <img src={`${detailProps.icon.data.attributes.url}`} className="filter-white" alt="image"></img>
            }
          </NavLogo>
          <MobileIcon onClick={toggleMenu}>
            <NavLinks href="https://www.linkedin.com/company/ocean-group-2018" target="_blank">
                LinkedIn
              </NavLinks>
            <img src={menu} />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks href="https://www.linkedin.com/company/ocean-group-2018" target="_blank">
                LinkedIn
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='/' onClick={toggleContactUs}>
                <Text textKey="Contact us"/>
              </NavLinks>
            </NavItem>
            <NavBtn>
              <NavBtnLink onClick={toggleMenu}><img src={menu}/></NavBtnLink>
            </NavBtn>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
