import React from "react";
import image from "../../images/image.png"
import image_smail from "../../images/image_smail.png"
import Text from '../Text';
function IntroSection({isArabic}) {
  const arabicClassText1 = isArabic
  ? 'text-right'
  : '';
  const arabicClassText2 = isArabic
  ? 'text-right mr-24% sm:mr-0 sm:md:ml-12'
  : 'ml-24% sm:ml-0 sm:md:mr-12';
  const arabicClassText3 = isArabic
  ? 'flex mr-9% sm:mr-0 sm:flex-col justify-start flex-row-reverse'
  : 'flex ml-9% sm:ml-0 sm:flex-col';
  const arabicClassJustifyRight = isArabic
  ? 'justify-end'
  : '';
  
  return (
    <div className="bg-blue-450 flex flex-col">
      <div className={`flex flex-col justify-center mt-12 mb-20 sm:mb-108 pt-5 text-white tems-center px-10 lg:px-6 sm:px-4 text-114 tracking-456 sm:text-5-5xl leading-none tracking-4 sm:tracking-2 ${arabicClassText1}`}>
        <div>
          <Text textKey="Towards"/>
        </div>
        <div className={`flex ${arabicClassJustifyRight}`}>
          <div></div>
          <div className={arabicClassText2}>
            <Text textKey="Tomorrow's"/>
          </div>
        </div>
        <div className={arabicClassText3}>
          <div>
            <Text textKey="Prosperity"/>
          </div>
          <div className="flex text-2xl lg:text-xl md:text-base sm:text-base flex-wrap leading-9 mt-5 lg:mt-3 md:mt-1 ml-5 sm:ml-0 sm:mt-3 leading-140 tracking-normal font-normal">
            <p><Text textKey="Navigating Family Offices,"/><br/><Text textKey="Investments and Innovation"/></p>
          </div>
        </div>
      </div>
      <div className="flex relative">
        <img src={image} alt="image" className="w-full sm:hidden md:block" style={{minHeight:'300px'}}></img>
        <img src={image_smail} alt="image" className="w-full hidden sm:block" style={{height:'400px'}}></img>
      </div>
    </div>
  );
}

export default IntroSection;