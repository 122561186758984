import React from 'react'
import { useDetailProps } from '../../detailProvider';
import Text from '../Text';
const About = ({isArabic}) => {
  const arabicClassText = isArabic
  ? 'text-right'
  : '';
  const arabicClassOrder = isArabic
  ? 'order-2'
  : '';
  const detailProps = useDetailProps();

  return (
    <div id="about"> 
      <div className={`flex bg-white px-8 py-20 sm:flex-col ${arabicClassText}`}>
        <div className={`text-2xl sm:text-xl w-1/3 ${arabicClassOrder}`}>
          <Text textKey="About us"/>
        </div>
        <div className='flex flex-col w-2/3 sm:mt-6 sm:w-full'>
          <div className='text-40 md:text-3xl sm:text-2xl leading-140 md:leading-120 sm:leading-110 tracking-160'>
            <Text textKey={ detailProps.aboutUsTitle }/>
          </div>
          {
            detailProps.aboutUsDescription ? (
              <div className='text-2xl md:text-1xl sm:text-lg mt-6 leading-140 md:mt-8 sm:mt-10 text-gray-600'>
                  <Text textKey={ detailProps.aboutUsDescription }/>
                </div>
            ) : <></>
          }
        </div>
      </div>
    </div>
  )
}

export default About