import React from "react";
import email from '../images/email.svg';
import phone from '../images/phone.svg';
import global from '../images/global.svg';

import { emails } from '../config'

function Email() {
    const content = emails[0]
    return (
        <div className="flex justify-center">
            <div className="flex flex-col justify-center h-full" style={{maxWidth:'600px'}}>
                <div className="flex w-full my-32xl smd:my-8">
                    <div className="w-5/12 flex justify-center">
                        <img src={`${'..'}/${content.icon}`} alt="" className="w-1/12-2"></img>
                    </div>
                    <div className="flex w-7/12 flex-col border-l-1 border-blue-420 pl-64md py-3%">
                        <div className="text-16md smd:text-base sm:text-base font-medium leading-120">{ content.title }</div>
                        <div className="text-blue-450 smd:text-sm text-14md sm:text-sm leading-120">{ content.description }</div>
                        <div className="flex items-center mt-28md">
                            <img src={email} alt="email" className="w-1/12-3 mr-1.3%" style={{minWidth:'12px'}}></img>
                            <div className="text-14md smd:text-sm sm:text-sm leading-120">{ content.email }</div>
                        </div>
                        <div className="flex items-center my-1.3%">
                            <img src={phone} alt="phone" className="w-1/12-3 mr-1.3%" style={{minWidth:'12px'}}></img>
                            <div className="text-14md smd:text-sm sm:text-sm leading-120">{ content.phone }</div>
                        </div>
                        <div className="flex items-center">
                            <img src={global} alt="global" className="w-1/12-3 mr-1.3%" style={{minWidth:'12px'}}></img>
                            <div className="text-14md smd:text-sm sm:text-sm leading-120">{ content.url }</div>
                        </div>
                    </div>
                </div>
                <div className="text-grey-420 leading-150 text-12md smd:text-xs sm:text-xs mx-24x">
                    { content.content1 }
                </div>
                <div className="text-grey-420 leading-150 text-12md smd:text-xs sm:text-xs mx-24x my-24x">
                    { content.content2 }
                </div>
                <div className="text-grey-420 leading-150 text-12md smd:text-xs sm:text-xs mx-24x">
                    { content.content3 }
                </div>
                <div className="text-grey-420 leading-150 text-12md smd:text-xs sm:text-xs mx-24x my-24x text-right">
                    { content.content4 }
                </div>
            </div>
        </div>
    );
    }

export default Email;
