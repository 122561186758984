import React from 'react'
import { useDetailProps } from '../../detailProvider';
import Text from '../Text';

const Approach = ({isArabic}) => {
  const arabicClassText = isArabic
  ? 'text-right'
  : '';
  const arabicClassOrder = isArabic
  ? 'order-2'
  : '';
  const arabicClassJustify = isArabic
  ? 'ml-auto'
  : '';
  const detailProps = useDetailProps();
  const paragraphs = detailProps.approachDescription.split('\\n\\n');
  return (
    <div id="approach"> 
      <div className={`flex flex-col bg-white px-8 py-20 ${arabicClassText}`}>
        <div className={`w-4/10-2 sm:w-full md:w-full ${arabicClassJustify}`}>
          <div className='text-2xl md:text-1xl sm:text-lg'>
            <Text textKey="Our approach"/>
          </div>
          <div className='text-40 md:text-3xl sm:text-2lg my-10 leading-110'>
            <Text textKey={ detailProps.approachTitle }/>
          </div>
        </div>
        <div className='flex'>
          <div className={`w-4/10-2 sm:w-0 md:w-0 mr-1.5 md:mr-0 sm:mr-0 ${arabicClassOrder}`}></div>
          <div className='flex flex-col w-3/5 sm:w-full md:w-full'>
            <div className='text-2xl md:text-1xl sm:text-lg text-gray-600 leading-140 tracking-96 whitespace-pre-line'>
              {paragraphs.map((paragraph, index) => (
                <p key={index}><Text textKey={paragraph}/><br></br><br></br></p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Approach