import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import close from "../../images/close.svg"
import { getValue } from '../../utils';
import Text from '../Text';
export const SidebarContainer = ({ children, isOpen, bgColor }) => {
  const opacity = isOpen ? 'translate-x-0' : 'translate-x-full';
  const zIndex = isOpen ? "z-50" : "z-0";

  return (
    <div>
      <div className={`fixed inset-0 bg-black opacity-50 ${isOpen ? '' : 'hidden'}`}></div>
      <div
        className={`fixed z-50 inset-0 transform transition-transform ease-in-out duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <aside
          className={`absolute ${zIndex} w-9/12-1 h-full md:w-full sm:w-full top-0 right-0 ${opacity}`}
          style={{backgroundColor:bgColor}}
        >
          {children}
        </aside>
      </div>
    </div>
  );
};

export const CloseIcon = ({ ...rest }) => (
  <div {...rest} className="flex text-white text-base sm:text-sm items-center">
    <div className="mr-1.5 sm:mr-6"><Text textKey="Close"/></div>
    <img src={close} color="white" />
  </div>
);

export const SidebarActionLink = ({toggleContactUs, config}) => (
  <div className="flex text-white text-base items-center sm:text-sm">
    <a className="mr-16" href={ getValue(config, 'linkedinUrl') } target="_blank">LinkedIn</a>
    <div onClick={toggleContactUs} className="mr-16"><Text textKey="Contact us"/></div>
  </div>
);

export const SidebarLinkWrap = ({ children, ...rest }) => (
  <div
    {...rest}
    className="flex absolute top-10 right-10 bg-transparent text-3xl cursor-pointer outline-none"
  >
    {children}
  </div>
);

export const SidebarWrapper = ({ children }) => (
  <div className="flex text-white md:flex-col md:flex-col h-full md:mb-28 sm:mb-28">{children}</div>
);

export const SidebarMenu = ({ children,order }) => (
  <div style={order} className="grid grid-cols-1 gap-y-4 ml-4.6% w-1/2 mt-10% sm:mt-24% mb-15% md:w-full sm:w-full">
    {children}
  </div>
);

export const SidebarLink = ({ children, ...rest }) => (
  <LinkRouter
    {...rest}
      className="justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none"
    >
    {children}
  </LinkRouter>
);

export const SideBtnWrap = ({ children, classes }) => (
  <div className={`flex flex-col w-1/2 md:w-full sm:w-full justify-center ${classes}`}>{children}</div>
);

export const SidebarRoute = ({ children, ...rest }) => (
  <LinkRouter {...rest} className="text-24xl sm:text-20px w-full ml-6.2% text-normal lg:ml-5% mx-6.2% leading-120 opacity-50">
    {children}
  </LinkRouter>
);

export const SidebarDescription = ({ children }) => (
  <div className="text-18xl mx-6.2% lg:mx-5% md:mx-0 sm:text-lg sm:mx-4 mt-10 text-normal leading-120">
    {children}
  </div>
);
