import React from "react";
import Text from '../Text';
import {
  SidebarContainer,
  SidebarLinkWrap,
  SidebarActionLink,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SidebarDescription
} from "./SidebarElements";

function Sidebar({ isOpen, toggle, bgColor, email, toggleContactUs, config, isArabic, currentLanguage  }) {
  const isLanguageNonEnglish = currentLanguage  !== 'en';

  const addLanguageToUrl = (url) => {
    if (isLanguageNonEnglish) {
      const langParam = `lang=${currentLanguage }`;
      return url.includes('?') ? `${url}&${langParam}` : `${url}?${langParam}`;
    }
    return url;
  };
  const arabicClasses = isArabic
  ? 'text-right mr-5'
  : '';
  const arabicClassesOther = isArabic
  ? 'text-right'
  : '';
  const sidebarWrapperStyle = isArabic ? { order: 2 } : {};
  return (
    <SidebarContainer isOpen={isOpen} bgColor={bgColor}>
      <SidebarLinkWrap>
        <SidebarActionLink toggleContactUs={toggleContactUs} config={config}/>
        <CloseIcon onClick={toggle}/>
      </SidebarLinkWrap>
      <SidebarWrapper>
        <SidebarMenu order={sidebarWrapperStyle}>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`}
          href={addLanguageToUrl("http://oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Group"/>
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://wealth.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Wealth"/>
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://genesis.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Genesis"/>
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://ventures.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Ventures"/>
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://finance.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Finance"/>
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://commodities.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Commodities"/>
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://cremona.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Cremona"/>
          </a>
          {/* <SidebarLink to="../detail/8" onClick={toggle}>
            Ocean Narbo
          </SidebarLink> */}
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://cs.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Corporate Provider"/> 
          </a>
          <a className={`leading-10 justify-center text-32xl sm:text-2xl text-normal sm:tranking-3 cursor-pointer hover:opacity-100 text-white opacity-50 transition-transform transform hover:translate-x-2 duration-200 ease-in-out no-underline list-none ${arabicClasses}`} 
          href={addLanguageToUrl("http://elements.oceangroup.global/")} onClick={toggle}>
            <Text textKey="Ocean Elements"/>
          </a>
        </SidebarMenu>
        <SideBtnWrap classes={arabicClassesOther}>
            <SidebarRoute  to="/">
              { email }
            </SidebarRoute>
          <SidebarDescription>
            <Text textKey="Commercial office no. B110, Abu Hail Building 13, Abu Hail, Dubai UAE"/>
          </SidebarDescription>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar;
