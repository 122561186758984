import React from "react";
import { Link as LinkScroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

import close from "../../images/close.svg"

import Text from '../Text';

export const ContactContainer = ({ children, isOpen }) => {
  const opacity = isOpen ? 'translate-x-0' : 'translate-x-full';
  const zIndex = isOpen ? "z-50" : "z-0";

  return (
    <div>
      <div className={`fixed inset-0 bg-black opacity-50 ${isOpen ? '' : 'hidden'}`}></div>
      <div
        className={`fixed z-50 inset-0 transform transition-transform ease-in-out duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <aside
          className={`absolute ${zIndex} w-9/12-1 h-full md:w-full sm:w-full bg-white top-0 right-0 ${opacity}`}
        >
          {children}
        </aside>
      </div>
    </div>
  );
};

export const CloseIcon = ({ ...rest }) => (
  <div {...rest} className="flex text-black text-base items-center">
    <img src={close} className="filter-black" />
  </div>
);

export const SidebarActionLink = () => (
  <div className="flex text-black text-base items-center">
    <div className="mr-16">LinkedIn</div>
  </div>
);

export const SidebarLinkWrap = ({ children, ...rest }) => (
  <div
    {...rest}
    className="flex absolute top-12 right-10 bg-transparent text-3xl cursor-pointer outline-none"
  >
    {children}
  </div>
);

export const SidebarWrapper = ({ children }) => (
  <div className="flex text-black md:flex-col md:flex-col md:mb-28 sm:mb-28">{children}</div>
);

export const FormContainer = ({ bgColor, textAlign }) => (
  
  <div className="mt-108 pb-24 pl-80x md:pl-12 sm:pl-4 md:pr-12 sm:pr-4 sm:pb-10 w-1/2 md:w-full sm:w-full">
    <div className={`text-5xl mb-16 sm:mb-12 text-black sm:text-26px ${textAlign}`}>
      <Text textKey="Contact us"/>
    </div>
    <div className="mb-10 sm:mb-8">
      <input className={`appearance-none border-b-1 rounded w-full py-2 text-black leading-tight focus:outline-none focus:shadow-outline ${textAlign}`} id="username" type="text" placeholder="Username" />
    </div>
    <div className="mb-10 sm:mb-8">
      <input className={`appearance-none border-b-1 rounded w-full py-2 text-black leading-tight focus:outline-none focus:shadow-outline ${textAlign}`} id="email" type="text" placeholder="Email" />
    </div>
    <div className="mb-10 sm:mb-8">
      <input className={`appearance-none border-b-1 rounded w-full py-2 text-black leading-tight focus:outline-none focus:shadow-outline ${textAlign}`} id="phone" type="text" placeholder="Phone" />
    </div>
    <div className="mb-6 sm:mb-4">
      <textarea rows="4"  max-rows="4" className={`appearance-none border-b-1 rounded w-full py-2 text-black leading-tight focus:outline-none focus:shadow-outline ${textAlign}`} id="phone" type="text" placeholder="Your message" />
    </div>
    <div className="inline-flex justify-center items-center py-4 px-8 rounded-4xl text-white" style={{backgroundColor:bgColor}}>
      Send message
    </div>
  </div>
);

export const SidebarLink = ({ children, ...rest }) => (
  <LinkScroll
    {...rest}
    className="justify-center text-2xl text-black opacity-50 hover:ml-2.5 hover:opacity-100 cursor-pointer no-underline list-none transition ease-in-out duration-200"
  >
    {children}
  </LinkScroll>
);

export const SideBtnWrap = ({ children }) => (
  <div className="flex flex-col justify-center pl-12% pr-6.2% xlg:px-16 lg:px-14 md:px-10 sm:px-10 w-1/2 md:w-full sm:w-full">{children}</div>
);

export const SidebarRoute = ({ children, textAlign, ...rest }) => (
  <LinkRouter {...rest} className={`text-2xl lg:text-3-5xl sm:text-20px sm:opacity-50 leading-120 ${textAlign}`}>
    {children}
  </LinkRouter>
);

export const SidebarDescription = ({ children, textAlign }) => (
  <div className={`text-lg xlg:text-base sm:text-3-5xl mt-8 leading-120 ${textAlign}`}>
    {children}
  </div>
);
