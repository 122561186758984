// LanguageProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import en from '../../locales/en.json';
import ru from '../../locales/ru.json';
import zh from '../../locales/zh.json';
import ar from '../../locales/ar.json';

const translations = {
  en,
  ru,
  zh,
  ar,
};

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState('en');
  const [currentTranslations, setCurrentTranslations] = useState(translations[locale]);

  useEffect(() => {
    setCurrentTranslations(translations[locale]);
  }, [locale]);

  const translate = (key) => currentTranslations[key] || key;

  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <LanguageContext.Provider value={{ translate, changeLocale, currentLocale: locale }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  return useContext(LanguageContext);
};

export { LanguageProvider, useLanguage };
