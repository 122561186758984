import React from 'react'
import Text from '../Text';
import { useDetailProps } from '../../detailProvider';

const Contact = ({ toggleContactUs, isArabic }) => {
  const arabicClassText = isArabic
  ? 'ml-auto'
  : '';
  const detailProps = useDetailProps();

  return (
    <div id="contact"> 
      <div className='flex flex-col bg-white px-8 py-20' style={{ backgroundColor: detailProps.secondaryColor }}>
        <div className={`text-40 lg:text-3xl sm:text-2xl leading-130 ${arabicClassText}`}>
          <Text textKey={ detailProps.contactTitle }/>
        </div>
        <div className={`mt-10 ${arabicClassText}`}>
          <a onClick={toggleContactUs} className='leading-110 border-2 px-4 py-2 border-gray-320 text-base cursor-pointer' style={{borderRadius:'50px'}}>
            <Text textKey="Contact us"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact