import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ContactUs from "../components/ContactUs";
import IntroDetailSection from "../components/IntroDetailSection";
import About from "../components/AboutSection";
import Expertise from "../components/ExpertiseSection"
import Approach from "../components/Approach"
import Contact from "../components/Contact"
import Portfolio from "../components/PortfolioSection"
import Footer from "../components/FooterSection"

import { DetailPropsProvider } from "../detailProvider"

import axios from "axios";
import qs from "qs";

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;

function Detail() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContactUs, setisOpenContactUs] = useState(false);
  const [isArabic, setIsArabic] = useState(false);
  const [currentLocale, setCurrentLocale] = useState('en');

  // let { id } = useParams();
  const subdomain = window.location.host.split(".")[0];
  const subdomainToIDMap = {
    wealth: 1, 
    genesis: 2,
    ventures: 3,
    finance: 4,
    commodities: 5,
    cs: 6,
    cremona: 7,
    // narbo: 8,
    elements: 9,
    // Add more mappings as needed
  };
  const detailID = subdomainToIDMap[subdomain];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top immediately.
  }, []);


  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleContactUs = () => {
    setisOpenContactUs((prev) => !prev);
  };
  
  const [service, setService] = useState([]);

  const query = qs.stringify({ 
    populate: ['icon', 'image', 'expertise', 'portfolios', 'portfolios.image'],
    fields: '*',
    publicationState: 'live',
    locale: ['en'],
  }, {
    encodeValuesOnly: true, // prettify url
  });
  
  const axiosConfig = {
    headers: { Authorization: `Bearer 46c9745cc8257cf925ecf2afd8a1b6d906b78c9b5381a1a6039824b9de51c3c643138379bf6b01282f5d75224eabd5f1ea21c276b2dbcec56657877da172ae23d948d76e29620049c4eb8540c2511d329c9b37b4a9d3bbe19a4e283146a6318774538c77c55efab30f1e4fbf594697e921a1a9ba63cfbccd8433ee05b73d1be9` }
  };

  const updateLocale = (newLocale) => {
    setCurrentLocale(newLocale);
    setIsArabic(newLocale === 'ar');
  };

  // function to get all tasks
  const getService = async () => {
    try {
      // fetch service from strapi
      const res = await axios.get(`${serverUrl}/api/services/${subdomainToIDMap[subdomain]}?${query}`,axiosConfig);
      // get result from nested object destructuring
      const {
        data: { data },
      } = res;
      setService(data);
      console.log(data)
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    // get service
    getService();
  }, []);  

  return (
    <DetailPropsProvider props={service.attributes}>
      <div className="flex justify-center bg-gray-100">
        {
          service.attributes ? (
            <div className="w-full items-center">
              <Sidebar isOpen={isOpen} toggle={toggleMenu} bgColor={service.attributes.primaryColor} email={service.attributes.email} toggleContactUs={toggleContactUs} isArabic={isArabic} currentLanguage={currentLocale}/>
              <ContactUs isOpenContactUs={isOpenContactUs} toggle={toggleContactUs} bgColor={service.attributes.primaryColor} email={service.attributes.email} isArabic={isArabic}/>
              <Navbar toggleMenu={toggleMenu} toggleContactUs={toggleContactUs} isMain={false} isArabic={isArabic}/>
              <IntroDetailSection isArabic={isArabic}/>
              <About isArabic={isArabic}/>
              <Expertise isArabic={isArabic}/>
              <Approach isArabic={isArabic}/>
              <Portfolio isArabic={isArabic}/>
              <Contact toggleContactUs={toggleContactUs} isArabic={isArabic}/>
              <Footer color="bg-service-1" toggleContactUs={toggleContactUs} isDetail={true} isArabic={isArabic} currentLocale={currentLocale} updateLocale={updateLocale}/>
            </div>
          ) : (
            <></>
          )
        }
      </div>
    </DetailPropsProvider>
  );
}

export default Detail;
