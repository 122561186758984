import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages";
import Detail from "./pages/detail";
import Email from "./pages/email";

function App() {
  const subdomain = window.location.host.split(".")[0];

  console.log(subdomain)
  return (
    <Router>
      <Switch>
        {subdomain === "oceangroup" || subdomain === "localhost:3000" || subdomain === "www" ? (
          <Route path="/" component={Home} exact />
          ) : (
          <Route component={Detail} exact />
      )}
      <Route path="/email" component={Email} exact />
      </Switch>
    </Router>
  );
}

export default App;
