import React from 'react'
import { useDetailProps } from '../../detailProvider';
import Text from '../Text';

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;

const Portfolio = ({isArabic}) => {
  const arabicClassText = isArabic
  ? 'text-right'
  : '';
  const detailProps = useDetailProps();
  const getPortfolioLink = (title) => {
    if (title === "Nanos payments") {
      return "https://nanospay.ai";
    } else if (title === "Ocean Finance") {
      return "https://finance.oceangroup.global";
    } else {
      return "";
    }
  };
  
  const renderItem = (id, portfolio) => {
    const portfolioLink = getPortfolioLink(portfolio.title);
    return (
      <a href={portfolioLink} target='_blank' key={id} className='flex flex-col justify-between h-80 p-8' style={{ backgroundColor:detailProps.secondaryColor, borderRadius: '24px' }}>
        <div>
        <img src={portfolio.image.data.attributes.url} alt={portfolio.title} className='h-8'></img>
        </div>
        <div className='text-xl'>
          <Text textKey={portfolio.title}/>
        </div>
      </a>
    )
  }

  return (
    <div id="portfolio">
      {
        detailProps.portfolios?.data?.length > 0 ? (
          <div className={`flex flex-col bg-white px-8 py-20 ${arabicClassText}`}>
            <div className='text-2xl'>
              <Text textKey="Our portfolio"/>
            </div>
            <div className='flex flex-col'>
              <div className='grid grid-cols-2 sm:grid-cols-1 gap-4 mt-10'>
                {
                  detailProps.portfolios.data.map(portfolio => {
                    return (renderItem(portfolio.id, portfolio.attributes))
                  })    
                }
              </div>
            </div>
          </div>
        ) : <></>
      }
    </div>
  )
}

export default Portfolio