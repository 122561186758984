import React from "react";
import Text from '../Text';
import { useDetailProps } from '../../detailProvider';

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;

function IntroDetailSection({isArabic}) {
  const arabicClassText = isArabic
  ? 'text-right'
  : '';
  const arabicClassMargin = isArabic
  ? 'ml-40 leading-100'
  : 'mr-40 leading-100';
  const detailProps = useDetailProps();

  return (
    <div className="flex flex-col" style={{backgroundColor:detailProps.primaryColor}}>
      <div className={`flex flex-col justify-center mt-16 sm:mt-84px mb-20 sm:mb-108 pt-5 text-white tems-center px-8 md:px-6 sm:px-4 text-100 xlg:text-8xl lg:text-7xl md:text-6xl sm:text-5xl xsm:text-4-5xl ${arabicClassText}`}>
        <div className={arabicClassMargin}>
          <Text textKey={detailProps.title}/>
        </div>
        <div className="text-2xl sm:text-3-5xl w-full mt-6 md:mt-5 sm:mt-5 leading-140 w-477">
          <Text textKey={detailProps.description}/>
        </div>
      </div>
      <img src={`../${detailProps.image.data.attributes.name}`} alt="image" className="w-full" style={{minHeight:'300px'}}></img>
    </div>
  );
}

export default IntroDetailSection;