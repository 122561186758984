import React, { useState, useEffect } from "react";
import arraw from '../images/arraw-bottom.svg';
import { animateScroll as scroll, Link } from "react-scroll";
import Text from './Text';
function MeetOurCompany() {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToCompanies = () => {
    scroll.scrollTo(1440 / window.screen.availWidth * 2420);
  };

  return (
    <div>
      {!isHidden && (
        <Link to="service" smooth={true} duration={500} className="flex fixed bottom-35xl text-white mx-40xl sm:mx-6 cursor-pointer">
          <div className="text-base text-white mr-2.5"><Text textKey="Meet our companies"/></div>
          <img src={arraw} alt="arrow" className="animate-bounce" />
        </Link>
      )}
    </div>
  );
}

export default MeetOurCompany;