import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import Text from '../Text';
import arrow from "../../images/arraw.svg"
import { Link } from 'react-router-dom';

const serverUrl = process.env.REACT_APP_STRAPI_SERVER;

const idToSubdomainMap = {
  1: 'wealth',
  2: 'genesis',
  3: 'ventures',
  4: 'finance',
  5: 'commodities',
  6: 'cs',
  7: 'cremona',
  8: 'narbo',
  9: 'elements',
};

const Service = ({ services, isArabic }) => {
  const arabicClassText = isArabic
? 'text-right'
: '';
  const renderItem = (id, service) => {
    const detailId = idToSubdomainMap[id];
    return (
      <a key={ id } href={`http://${detailId}.oceangroup.global`} className="flex flex-col">
        <div className='h-2 rounded-t-lg' style={{backgroundColor:service.primaryColor}}></div>
        <div className='pt-81% relative border-b border-l border-r rounded-b-lg'>
          <div className='absolute top-0 flex flex-col justify-between p-8 h-full w-full'>
            <div className='flex justify-between'>
              <img src={service.icon.data.attributes.url}></img>
              <img src={arrow}></img>
            </div>
            <div className={`text-gray-500 text-14xl sm:text-base md:text-xs leading-140 ${arabicClassText}`}>
              <Text textKey={service.comment}/>
            </div>
          </div>
        </div>
      </a>
    )
  }

  return (
    <div id='service'>
      <div className='px-11 bg-white sm:px-6 py-20 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8'>
        {
          services.map(service => {
            return (renderItem(service.id, service.attributes))
          })    
        }
      </div>
    </div>
  )
}

export default Service

