import React from 'react';
import payment from '../../images/nanos-payment.svg';
import { getValue } from '../../utils';
import Text from '../Text';
const Partnership = ({ config, isArabic }) => {
  const arabicClassText = isArabic
  ? 'text-right'
  : '';

  const arabicClassOrder = isArabic
  ? 'order-2 py-14 sm:pt-0 sm:pb-80x pr-none pl-32 lg:pr-0 border-l-1'
  : 'py-14 sm:pt-0 sm:pb-80x pr-32 lg:pr-80x md:pr-0 sm:pr-0 ';

  const arabicClassNoBorder = isArabic
  ? 'border-none'
  : '';
  return (
    <div id='platform'>
      <div className='bg-white px-11'>
        <div className={`py-20 flex flex-col ${arabicClassText}`}>
          <div className={`text-24xl mb-10 sm:text-2xl leading-110`}>
            <Text textKey="Partnership"/>
          </div>
          <div className='flex flex-row sm:flex-col md:flex-col'>
            <div className={`text-50 leading-110 lg:text-3xl md:text-3xl sm:text-26px w-3/5 md:w-full sm:w-full ${arabicClassOrder}`}>
              <Text textKey={ getValue(config, 'partnerShip') }/>
            </div>
            <div className={`border-l-1 flex justify-center w-2/5 md:w-full sm:w-full border-gray-300 md:border-t-1 md:border-l-0 sm:border-l-0 sm:border-t-1 ${arabicClassNoBorder}`}>
              <div className='flex flex-col py-14 md:px-80x sm:px-5 w-5/12 md:w-full sm:w-full'>
                <img src={payment} alt='payment' className='mt-16 lg:mt-12 md:mt-6 sm:mt-3 w-full px-5%'></img>
                <div className='text-14xl md:text-sm sm:text-sm mt-28 text-center leading-140 opacity-60 w-full'>
                  <Text textKey="A conglomerate Fintech group"/><br/><Text textKey="specializing in virtual assets."/>
                </div>
                <a href='https://nanospay.ai' target='_blank' className='text-20xl md:text-xl sm:text-xl my-6 text-center leading-120 w-full'>
                  nanospay.ai
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partnership