import React, { useState, useEffect } from 'react';
import logo from '../../images/logo-image.svg';
import { useDetailProps } from '../../detailProvider';
import { getValue } from '../../utils';
import { useLanguage } from '../LanguageProvider';
import Text from '../Text';
import { Dropdown } from 'flowbite';

const subdomainToPdfFolder = {
  group: require.context('../../pdf/group', false, /\.pdf$/),
  wealth: require.context('../../pdf/wealth', false, /\.pdf$/),
  genesis: require.context('../../pdf/genesis', false, /\.pdf$/),
  finance: require.context('../../pdf/finance', false, /\.pdf$/),
  commodities: require.context('../../pdf/commodities', false, /\.pdf$/),
  cs: require.context('../../pdf/cs', false, /\.pdf$/),
  cremona: require.context('../../pdf/cremona', false, /\.pdf$/),
  elements: require.context('../../pdf/elements', false, /\.pdf$/),
};
const Footer = ({ toggleContactUs, isDetail, config, updateLocale }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const langFromParam = urlParams.get('lang');
  
  const detailProps = useDetailProps();
  const { changeLocale, currentLocale } = useLanguage();
  const [currentLanguage, setCurrentLanguage] = useState(getFullLanguageName(currentLocale));
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  // Function to get the full name of the language based on the locale
  function getFullLanguageName(locale) {
    switch (locale) {
      case 'en':
        return 'English';
      case 'ar':
        return 'العربية';
      case 'zh':
        return '简体中文';
      case 'ru':
        return 'Русский';
      default:
        return 'Unknown';
    }
  }

  const handleLocaleChange = (newLocale) => {
    localStorage.setItem('selectedLanguage', newLocale);

    changeLocale(newLocale);
    setCurrentLanguage(getFullLanguageName(newLocale));
    updateLocale(newLocale);
    toggleDropdown();
  };

  useEffect(() => {
    // Get the user's preferred language from the browser
    const userPreferredLanguage = navigator.language.split('-')[0];

    const storedLanguage = localStorage.getItem('selectedLanguage');

    // Set the initial locale to the user's preferred language or 'en' if not supported
    const initialLocale = ['ar', 'zh', 'ru'].includes(langFromParam)
      ? langFromParam
      : ['en', 'ar', 'zh', 'ru'].includes(storedLanguage)
      ? storedLanguage
      : ['en', 'ar', 'zh', 'ru'].includes(userPreferredLanguage)
      ? userPreferredLanguage
      : 'en';

      handleLocaleChange(initialLocale)
  }, [langFromParam]); // Empty dependency array ensures this runs only once on mount

  const getSvgClass = () => (currentLocale === 'ru' ? 'ms-1' : 'ms-3');

  const toggleDropdown = () => {
    setDropdownVisible(prevVisible => !prevVisible);
  };
  
  const subdomain = window.location.host.split(".")[0];
  const pdfFolder = subdomainToPdfFolder[subdomain] || subdomainToPdfFolder.group;

  const privacyPolicy = pdfFolder('./privacy_policy.pdf');
  const cookiePolicy = pdfFolder('./cookie_policy.pdf');
  const corporateInformation = pdfFolder('./corporate_information.pdf');
  const securityDataRetentionPolicy = pdfFolder('./security_data_retention_policy.pdf');
  const termsConditions = pdfFolder('./terms_conditions.pdf');

  const isArabic = currentLocale === 'ar';
  
  const arabicClassText = isArabic
  ? 'text-right'
  : '';

  const arabicClassMargin = isArabic
  ? 'ml-auto'
  : '';

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const currentEmail = isDetail ? detailProps.email : getValue(config, 'email');

  let Domain;

  switch (subdomain) {
    case 'oceangroup':
      Domain = 'Ocean Group';
      break;
    case 'cs':
      Domain = 'Ocean Corporate Provider';
      break;
    case 'wealth':
      Domain = 'Ocean Wealth';
      break;
    case 'elements':
      Domain = 'Ocean Elements';
      break;
    case 'genesis':
      Domain = 'Ocean Genesis';
      break;
    case 'finance':
      Domain = 'Ocean Finance';
      break;
    case 'cremona':
      Domain = 'Ocean Cremona';
      break;
    case 'commodities':
      Domain = 'Ocean Commodities';
      break;
    default:
      Domain = capitalizeFirstLetter(subdomain);
      break;
  }
  let domainFooter;

  if (subdomain === 'oceangroup' || subdomain === 'localhost:3000') {
    domainFooter = (
      <div>
        © 2023 Ocean Group
      </div>
    );
  } else {
    domainFooter = (
      <div>
        © 2023 {Domain} <Text textKey="is part of"/> <a className='underline' href='https://oceangroup.global/'>Ocean Group</a>
      </div>
    );
  }
  return(
    <div id='footer'>
      <div className={`px-8 pt-16 pb-10 text-white ${arabicClassText}`} style={{ backgroundColor:(detailProps?detailProps.primaryColor:"#3E5D87")}}>
        <div className="flex sm:flex-col justify-between opacity-100">
        <img className={`w-12 opacity-30`} src={logo} alt="Logo" />
          <a href={`mailto:`+currentEmail} className={`text-64 md:text-4xl sm:w-full sm:text-xl sm:mt-20 opacity-50`}>
            {
              currentEmail
            }
          </a>
        </div>
        <div className='flex text-base mt-20'>
          <a href="https://www.linkedin.com/company/ocean-group-2018" target="_blank">LinkedIn</a>
          <a onClick={toggleContactUs} className='cursor-pointer ml-11'><Text textKey="Contact us"/></a>
        </div>
        {
          !isDetail ? (
            <div className={`text-2xl pt-12 pb-10 font-normal w-340px sm:w-full text-2xl ${arabicClassMargin}`}>
              <Text textKey={ getValue(config, 'address') }/>
            </div>
          ) : <div className="pt-12 pb-24"></div>
        }
        <div className="mb-7 w-max ml-auto relative">
          <button onClick={toggleDropdown} className="text-white text-base opacity-50 bg-none font-medium text-sm px-4 py-2 text-center inline-flex items-center border-solid border-2 rounded-3xl w-108 ml-auto" type="button">
            {currentLanguage}
            <svg className={`w-2.5 h-2.5 ${getSvgClass()}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
            </svg>
          </button>
          <div style={{ backgroundColor:(detailProps ? detailProps.primaryColor:"#3E5D87"), top: '-140px'}} className={`z-10 ${isDropdownVisible ? 'hidden' : ''} divide-y divide-gray-100 rounded-lg shadow w-108 border-solid border-white border-opacity-50 border-2 absolute`}>
              <ul className="px-2 py-2 text-sm text-gray-700 dark:text-gray-200 w-auto" aria-labelledby="dropdownTopButton">
                <li>
                  <button onClick={() => handleLocaleChange('en')} className="text-white block px-2 py-1 rounded-md hover:bg-white hover:bg-opacity-10">English</button>
                </li>
                <li>
                  <button onClick={() => handleLocaleChange('ar')} className="text-white block px-2 py-1 rounded-md hover:bg-white hover:bg-opacity-10">العربية</button>
                </li>
                <li>
                  <button onClick={() => handleLocaleChange('zh')} className="text-white block px-2 py-1 rounded-md hover:bg-white hover:bg-opacity-10">简体中文</button>
                </li>
                <li>
                  <button onClick={() => handleLocaleChange('ru')} className="text-white block px-2 py-1 rounded-md hover:bg-white hover:bg-opacity-10">Русский</button>
                </li>
              </ul>
          </div>
        </div>

        <div className="flex justify-between items-center sm:items-start sm:flex-col">
          <div className="flex md:flex-col justify-between opacity-50 text-base w-full">
            {domainFooter}
            <div className="flex sm:mt-4 sm:flex-col">
              <a onClick={() => window.open(privacyPolicy)} target = "_blank" className="sm:ml-5 cursor-pointer">
                <Text textKey="Privacy policy"/>
              </a>
              <a onClick={() => window.open(cookiePolicy)} className="ml-5 cursor-pointer">
                <Text textKey="Cookie Policy"/>
              </a>
              <a onClick={() => window.open(securityDataRetentionPolicy)} className="ml-5 cursor-pointer">
                <Text textKey="Security & Data Policy"/>
              </a>
              <a onClick={() => window.open(termsConditions)} className="ml-5 cursor-pointer">
                <Text textKey="Terms & Conditions"/>
              </a>
              <a onClick={() => window.open(corporateInformation)} className="ml-5 cursor-pointer">
                <Text textKey="Corporate Information"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer