import React from 'react';
import Text from '../Text';
const News = ({ news }) => {

  const renderItem = (id, news, index) => {
    return (
      <div key={id} className={`flex flex-col md:w-full py-10 mb-20 sm:my-0 px-8 md:px-0 ${ index > 0 ? ' border-gray-300 border-t-0 md:border-t-1 sm:border-t-1' : ''}`}>
        <div className='pb-8 font-14xl sm:font-medium text-sm'><Text textKey={ news.date }/></div>
        <div className='leading-120 text-24xl sm:text-2xl font-normal'><Text textKey={ news.title }/></div>
      </div>
    )
  }
  
  return (
    <div id='innovation'>
      <div className='flex flex-col bg-white px-11'>
        <div className='text-24xl sm:text-2xl pt-80x pb-10 sm:pb-0'>
          <Text textKey="Latest News"/>
        </div>
        <div className='grid grid-cols-3 md:grid-cols-1 gap-8'>
          {
            news.map((item, index) => {
              return (renderItem(item.id, item.attributes, index))
            })  
          }
        </div>
      </div>
    </div>
  )
}

export default News