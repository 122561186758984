import React from 'react'
import { useDetailProps } from '../../detailProvider';
import Text from '../Text';
const Expertise = ({isArabic}) => {
  const arabicClassText = isArabic
  ? 'text-right'
  : '';
  const arabicClassOrder = isArabic
  ? 'order-2'
  : '';
  const detailProps = useDetailProps();

  const renderItem = (index, additionalClass) => {
    var expertise = detailProps.expertise.data[index].attributes

    return (
      <div key={detailProps.expertise.data[index].id} className={`flex flex-col p-16 sm:px-0 sm:py-10 md:p-12 lg:p-14 ${additionalClass}`}>
        <div className='text-32 md:text-2xl tracking-128 sm:text-1xl leading-105'>
          <Text textKey={expertise.title}/>
        </div>
        <div className='text-base text-gray-450 mt-6 leading-140 tracking-64'>
          <Text textKey={expertise.description}/>
        </div>
      </div>
    )
  }

  const isGrid = () => {
    return detailProps.expertise.length == 4
  }

  const renderGrid = () => {
    return (
      <div className='flex flex-col'>
        <div className='flex w-full lg:flex-col sm:flex-col md:flex-col'>
          {
            renderItem(0, 'w-1/2 lg:w-full sm:w-full md:w-full')
          }
          <div className='border-r-1 my-10 lg:w-full sm:w-full md:w-full lg:border-t-1 sm:border-t-1 md:border-t-1'></div>
          {
            renderItem(1, 'w-1/2 lg:w-full sm:w-full md:w-full')
          }
        </div>
        <div className='flex w-full'>
          <div className='border-b-1 w-1/2 mx-10 lg:w-full sm:w-full md:w-full lg:w-full sm:w-full md:w-full'></div>
          <div className='border-b-1 w-1/2 mx-10 lg:hidden sm:hidden md:hidden'></div>
        </div>
        <div className='flex w-full lg:flex-col sm:flex-col md:flex-col'>
          {
            renderItem(2, 'w-1/2 lg:w-full sm:w-full md:w-full')
          }
          <div className='border-r-1 my-10 lg:w-full sm:w-full md:w-full lg:border-t-1 sm:border-t-1 md:border-t-1'></div>
          {
            renderItem(3, 'w-1/2 lg:w-full sm:w-full md:w-full')
          }
        </div>
      </div>
    )
  }

  const renderView = () => {
    return (
      <>
        {detailProps.expertise.data.map((service, index) => {
          if (index % 3 === 0) {
            return renderRow(Math.floor(index / 3), index);
          }
          return null;
        })}
      </>
    );
  };
  
  const renderRow = (rowIndex, startIndex) => {
    const itemsInRow = Math.min(3, detailProps.expertise.data.length - startIndex);
  
    return (
      <div key={rowIndex} className='flex flex-col'>
        {rowIndex > 0 && (
          <div className='flex w-full'>
            {Array.from({ length: itemsInRow }, (_, i) => (
              <div key={startIndex + i} className={`border-t-1 w-${1 / itemsInRow} mx-10`}></div>
            ))}
          </div>
        )}
        <div className='flex w-full'>
          <div className='flex flex-col'>
            <div className={`flex w-full lg:flex-col sm:flex-col md:flex-col`}>
              {Array.from({ length: itemsInRow }, (_, i) => (
                <React.Fragment key={startIndex + i}>
                  {renderItem(startIndex + i, `w-1/3 lg:w-full sm:w-full md:w-full`)}
                  {i < itemsInRow - 1 && <div className='border-r-1 my-10'></div>}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="expertise"> 
      <div className={`flex flex-col bg-white px-8 py-20 ${arabicClassText}`}>
        <div className={`text-2xl w-full ${!isGrid() ? 'w-full' : ''}`}>
          <Text textKey={detailProps.expertiseTitle}/>
        </div>
        <div className='flex'>
          {/* <div className={`w-1/3 lg:w-0 sm:w-0 md:w-0 ${arabicClassOrder}`}></div> */}
          <div className={`flex flex-col w-2/3 lg:w-full sm:w-full md:w-full mt-10 ${!isGrid() ? 'w-full' : ''}`}>
            {
              isGrid() ? renderGrid() : renderView()
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Expertise