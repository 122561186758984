import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import arraw from '../../images/arraw-bottom.svg';

export const Nav = ({ isMain, color, children }) => {
  return (
    <nav
      className={`flex flex-col lg:transition-all lg:duration-500 lg:ease-linear pt-2`}
      style={{backgroundColor:color}}
    >
      {
        isMain ? (
          <div className="flex">
            <div className="text-base text-white mr-10">Meet our companies</div>
            <img src={arraw} alt="arrow"></img>
          </div>
        ) : <></>
      }
      <div className="h-24 flex justify-center items-center text-base">
        {children}
      </div>
    </nav>
  );
};

export const NavbarContainer = ({ children }) => (
  <div className="flex justify-between h-24 w-full py-0 pl-10 pl-6 lg:px-6 sm:px-4  items-center max-w-screen-lg font-bold">
    {children}
  </div>
);

export const NavLogo = ({ children, ...rest }) => (
  <div
    {...rest}
    className="text-white justify-self-start cursor-pointer text-2xl flex items-center ml-0.5 font-bold no-underline"
  >
    {children}
  </div>
);

export const MobileIcon = ({ children, ...rest }) => (
  <div
    {...rest}
    className="hidden font-normal lg:flex items-center lg:text-3xl lg:text-white"
  >
    {children}
  </div>
);

export const NavMenu = ({ children }) => (
  <ul className="flex items-center list-none text-center -ml-5 lg:hidden">
    {children}
  </ul>
);

export const NavItem = ({ children }) => <li className="h-24 font-normal px-6">{children}</li>;

export const NavLinks = ({ children, ...rest }) => (
  <a
    {...rest}
    className="text-white flex items-center py-0 px-2 h-full cursor-pointer tracking-320 text-base leading-110"
  >
    {children}
  </a>
);

export const NavBtn = ({ children }) => (
  <nav className="flex items-center md:pr-20">{children}</nav>
);

export const NavBtnLink = ({ children, ...rest }) => (
  <div {...rest} className="px-4 py-2 uppercase cursor-pointer" style={{borderRadius:'50px'}}>
    {children}
  </div>
);
